/* You can add global styles to this file, and also import other style files */

body {
    font-family: 'Open Sans', sans-serif;
    padding: 0;
    margin: 0;
    background-color: #202945;
}

p {
    font-family: 'Roboto', sans-serif;
    color: #DBCCA8;
}

@media (min-width: 1200px) {
    .nav-bar-bottom
    {
      // display: none !important;
    }
}


.loader {
    border: 2px solid transparent;
    border-radius: 50%;
    border-top: 2px solid #DBCCA8;
    width: 20px;
    height: 20px;
    animation: spin 1s linear infinite;
  }
  
  .big-loader
  {
      margin: 0px auto;
      border-top: 2px solid #DBCCA8;
      width: 30px;
      height: 30px;
  }

  /* Safari */
@-webkit-keyframes spin {
    0% { -webkit-transform: rotate(0deg); }
    100% { -webkit-transform: rotate(360deg); }
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }


  input {

    background-color : #F1E5CF; 
  
  }

  textarea {

    background-color : #F1E5CF; 
  
  }